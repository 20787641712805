@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"








































































































































































































































































































$custom-letter-size: 26px

.item-drawer

  .drawer
    background: white

  &__header-actions
    display: flex
    justify-content: space-between

    .badge
      margin-right: 24px

    .btn
      margin-right: 16px

  &__body

    &.--loading
      height: calc(100vh - 120px)
      display: flex
      align-items: center
      justify-content: center

    .info-right__label
      font-size: 14px

  &__item-info
    margin-bottom: 32px
    padding: 0 40px

    .info-block__content

      p
        line-height: inherit

  &__section
    padding: 16px 40px
    border-top: 1px solid $color-ink-lightest

    .--biggest-percentage
      color: $color-primary
      font-weight: 600

    img
      max-width: 100%

    &-title
      margin: 16px 0px
      color: $color-ink-light

      i
        margin-right: 8px

    .item-drawer__section

      h4
        font-size: $font-size-m
        font-weight: $font-weight-bold

    .item-drawer__player

      +mq-l
        position: relative
        padding-bottom: 56.25%

      iframe
        width: 100%
        height: 350px
        border: none
        padding: 16px 0
        -webkit-box-sizing: border-box
        box-sizing: border-box

        +mq-l
          position: absolute
          top: 0
          left: 0
          height: 100%
          padding: 0

    .item-alternative__letter
      width: 24px
      height: 24px
      line-height: 24px

    .item-alternative
      border: unset
      box-shadow: unset
      cursor: default

      &__letter
        width: $custom-letter-size
        height: $custom-letter-size
        line-height: 24px

      &:hover
        box-shadow: unset

    .answer,
    .correct-answer
      border: 1px solid $color_success
      background: transparentize($color_success, 0.85)

      .item-alternative__letter
        background: $color_success
        color: white
        border-color: $color_success

    .wrong-answer
      border: 1px solid $color_danger
      background: transparentize($color_danger, 0.85)

      .item-alternative__letter
        background: $color_danger
        color: white
        border-color: $color_danger

    .item-drawer__explanation

      h4
        font-size: $font-size-m
        font-weight: $font-weight-bold

  &__answer
    display: flex
    justify-content: flex-end
    padding: 0 32px
    background: transparentize($color-ink-light, 0.8)
