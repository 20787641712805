@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"









































































































































































































































































































































































































































































































































$dropdown-max-height: 200px

.exam-result-items-table

  .item-answer
    display: flex
    align-items: center
    justify-content: flex-start

    span
      margin-right: 8px

  ::v-deep .sas-dropdown
    width: 100%

    .sas-dropdown-menu
      width: 100%
      max-height: $dropdown-max-height
      overflow-y: auto
      justify-content: unset

  .result-filter
    display: flex
    flex-wrap: wrap

    &__select
      min-width: 250px
      margin-right: 15px
      margin-bottom: 20px

      +mq-s
        width: 100%

  .drawer

    &__info-header
      display: flex

      +mq-s
        display: block

      .info-2 ~ .info-2
        margin-left: 32px

        +mq-s
          margin-left: 0
          margin-top: 16px

    &__info-footer
      margin-top: 32px
.bttn
  width: 36px
